body {
  /* font-family: "Roboto", sans-serif; */
  font-family: sans-serif;
}
html {
  overflow-x: hidden;
}
.logo {
  width: 17%;
}
.title {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 60px;
}
.subtitle {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 1px;
}
.subsubtitle {
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-style: italic;
}
.para {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.1px;
  word-spacing: 1.5px;
}
.parahighlight {
  color: #a0261d;
}
.parahighlighttext {
  color: #a0261d;
  font-weight: bold;
  font-size: 25px;
}
.watchtext {
  text-align: center;
  color: #a0261d;
  font-weight: bold;
  font-size: 40px;
}
.subpara {
  font-size: 18px;
  font-weight: 600;
  word-spacing: 1px;
  margin-bottom: 30px;
  line-height: 40px;
}
.alignment {
  text-align: center;
}
.marginalign {
  margin-top: 200px;
}
.button {
  padding: 15px 60px;
  border: none;
  background: #a0261d;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.titlemodel {
  text-align: center;
  font-size: 29px;
  font-weight: bold;
  letter-spacing: 1px;
}
.paramodel {
  color: #606060;
  text-align: center;
  font-size: 19px;
  font-weight: 500;
  word-spacing: 1.5px;
}
.inputbox {
  width: 100%;
  border: 1px solid #606060;
  padding: 15px 30px;
  border-radius: 10px;
  /* margin-bottom: 30px; */
}
.inputbox:focus {
  outline-color: #a0261d;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0px !important;
  padding-left: 48px;
  margin-left: 0;
  background: transparent;
  border: 1px solid #606060 !important;
  border-radius: 10px !important;
  line-height: 25px;
  height: 0;
  width: 100% !important;
  outline: #606060;
  padding: 25px 50px !important;
}
.modelbutton {
  width: 100%;
  padding: 15px 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  background: #a0261d;
  color: #ffffff;
  border: none;
  font-size: 25px;
  font-weight: bold;
}
.videoalignment {
  width: 100% !important;
  height: 500px !important;
}
.applybutton {
  padding: 15px 250px;
  border: none;
  background: #a0261d;
  color: #ffffff;
  font-size: 35px;
  font-weight: bolder;
}
.applybutton:hover {
  transform: scale(1.1);
}
.calenderbox {
  padding: 20px;
  border: 1px solid #606060;
  border-radius: 5px;
}
.calenderlogo {
  width: 20%;
}
.thankstitle {
  font-size: 35px;
  font-weight: 700;
}
.thankstitle1 {
  font-size: 28px;
  font-weight: 700;
}
.webinartext {
  text-align: center;
  font-size: 26px;
  font-weight: 700;
}
.thankssubtitle {
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
}
.videoalignmentcall {
  width: 100% !important;
  height: 350px !important;
}

/* modal start */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20000;
  padding: 10px;
}
.modal-content {
  min-height: 200px;
  background-color: white;
  border-radius: 10px;
}
.modalHeader {
  background-color: #34d0e7;
  color: #ffffff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.Modal-View {
  width: 52%;
}
.modal-footer {
  background-color: #f2f2f2;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.modal-footermodel {
  padding: 20px;
  background-color: #f2f2f2;
}
.modal-scroll-content {
  height: 56vh;
  overflow-y: auto;
}
.modal-content .close-icon {
  position: absolute;
  right: -15px;
  top: -15px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid rgb(255, 255, 255);
  padding: 5px;
  cursor: pointer;
  z-index: 100;
}
.modal-content .close-icon:hover {
  border: 2px solid #000;
}
/* modal end */
.validationmsg {
  color: red;
  font-size: 15px;
}
.bannerimage {
  width: 100%;
}
.freecall {
  font-size: 30px;
  font-weight: bold;
  color: #a0261d;
}
.clickbutton {
  font-size: 20px;
  font-style: italic;
  text-align: center;
}
.clickbuttonend {
  font-size: 20px;
  font-style: italic;
  text-align: center;
  font-weight: bold;
}
.book {
  font-size: 25px;
  font-weight: bold;
  color: #a0261d;
}
.icon {
  font-size: 25px;
  font-weight: bold;
  color: #a0261d;
}
.footertext {
  font-size: 11px;
}
.starimage {
  width: 90%;
}
.trustimage {
  width: 80%;
}
.validationmsg {
  margin-top: 10px;
}
